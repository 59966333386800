<template>
  <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h2 class="card-title">Mitarbeiter</h2>
                        <div class="clearfix card-tools">
                            <div class="row align-items-center" >                          
                                <div class="text-right col-md-12">

                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  
                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm mt-0">
                                                    <select class="form-control" name="" id="" v-model="params.filter">
                                                        <option value="all">Alle Mitarbeiter</option>
                                                        <option value="active">Aktive Mitarbeiter</option>
                                                        <option value="inactive">Inaktive Mitarbeiter</option>
                                                        <option value="left">Ausgetretene Mitarbeiter</option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm mt-0">
                                                    <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="loadEmployees" v-model="search" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadEmployees">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="mr-1 btn btn-default btn-sm" @click="loadEmployees">
                                                    <i class="fas fa-sync"></i>
                                                </button>             
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'employees.create'}" v-if="$auth.check('employees.create')"><i class="fas fa-plus-circle"></i></router-link>  
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="btn btn-sm btn-info" @click="toggleOptions"><i class="fas fa-cog"></i></button>
                                            </li>
                                        </ul>
                    
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        
                        <b-row style="border: 1px solid black;" v-if="showOptions">
                            <b-col>
                                <h5>Optionen</h5>
                                <fieldset class="form-group">
                                    <div class="form-row">
                                        <legend tabindex="-1" class="col-lg-2 bv-no-focus-ring col-form-label">Spalten</legend>
                                        <div class="col">
                                            <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchDate" v-model="showNumber">
                                                <label class="custom-control-label" for="customSwitchDate">Personalnummer</label>
                                            </div>

                                            <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchInfos" v-model="showEmail">
                                                <label class="custom-control-label" for="customSwitchInfos">Email</label>
                                            </div>
                                            <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchHours" v-model="showHours">
                                                <label class="custom-control-label" for="customSwitchHours">Stunden anzeigen</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </b-col>
                        </b-row>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th v-if="showNumber">
                                            <a href="#" @click.prevent="changeSort('number')">Personalnummer</a>
                                            <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('first_name')">Vorname</a>
                                            <span v-if="this.params.sort_field == 'first_name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'first_name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('last_name')">Nachname</a>
                                            <span v-if="this.params.sort_field == 'last_name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'last_name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th v-if="showEmail">
                                            <a href="#" @click.prevent="changeSort('email')">E-Mail</a>
                                            <span v-if="this.params.sort_field == 'email' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'email' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th v-if="showHours">
                                            Akt. Stunden
                                        </th>
                                        <th>Aktion</th>
                                    </tr>
                                </thead>
                                <tbody v-for="typed in types" :key="typed.type">
                                    <template v-if="filterEmployeeGroup(typed.type).length > 0">
                                        <tr>
                                            <th><b>{{typed.name}}</b></th>
                                            <th></th>
                                            <th></th>
                                            <th v-if="showHours"></th>
                                            <th v-if="showNumber"></th>
                                            <th v-if="showEmail"></th>
                                        </tr>
                                        <tr v-for="employee in filterEmployeeGroup(typed.type)" :key="employee.id" :class="employee.active == 1 ? '' : 'table-danger'">
                                            <td v-if="showNumber">
                                                {{employee.number}}
                                            </td>
                                            <td>{{employee.first_name}}</td>
                                            <td>{{employee.last_name}}</td>
                                            <td v-if="showEmail">
                                                {{employee.email}}
                                            </td>
                                            <td v-if="showHours">
                                                {{ calculateHours(employee).toFixed(2) }}
                                            </td>
                                            <td>
                                                <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'employees.edit', params: {id: employee.id}}"><i class="fas fa-fw fa-edit"></i></router-link>
                                                <router-link class="btn btn-primary btn-xs mr-1" :to="{name: 'employees.time-tracking', params: {id: employee.id}}" v-if="$auth.check('salaries.show')"><i class="fas fa-business-time"></i></router-link>             
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card-footer">
                        <pagination class="float-left" :data="employees" @pagination-change-page="loadEmployees" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Mitarbeiter</option>
                            <option value="50">50 Mitarbeiter</option>
                            <option value="75">75 Mitarbeiter</option>
                            <option value="100">100 Mitarbeiter</option>
                            <option value="125">125 Mitarbeiter</option>
                            <option value="150">150 Mitarbeiter</option>
                        </select>
                        <p v-if="notEmptyObject(employees)" class="float-right mr-2">Anzeige Eintrag {{ employees.meta.from }} - {{ employees.meta.to }} von {{ employees.meta.total }}</p>
                    </div>
                </div>
            </div>
        </div>
  </b-container>
</template>

<script>
import { mapPreferences } from 'vue-preferences'
import moment from 'moment'
export default {

    data(){
        return {
            showOptions: false,
            employees: [],
            types: [{type: 'vollzeit', name: 'Vollzeit'}, {type: 'teilzeit', name: 'Teilzeit'}, {type: 'gfb', name: 'Gfb'}, {type: 'agenten', name: 'Agenten'}, {type: 'azubi', name: 'Azubi'}],
            params: {
                sort_field: 'number',
                sort_direction: 'asc',
                per_page: 75,
                filter: 'active',
            },
            search: '',
        }
    },
    computed: {

        ...mapPreferences({
            showNumber: {
                defaultValue: true
            },
            showEmail: {
                defaultValue: false
            },
            showHours: {
                defaultValue: false
            },
        }),
    },

    watch: {

        params: {
            handler () {
                this.loadEmployees();
            },
            deep: true
        },
    },

    methods:{
        toggleOptions(){
            if(this.showOptions == false){
                this.showOptions = true;
            }
            else{
                this.showOptions = false;
            }
        },
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        resetSearch(){
            this.search = '';
            this.loadEmployees();
        },

        loadEmployees(page = 1) {
            this.axios
                .get("/employees", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.employees = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        filterEmployeeGroup(type)
        {
            let employees = this.employees.data.filter(employee => employee.type == type);
            return employees;
        },
        calculateHours(employee) {
            var date = moment();
            if(employee.salaries != undefined)
            {
                var salarie = employee.salaries.find(element => (element.year == date.format('Y') && element.month == date.format('M')));
                var hours = 0;
                if(salarie != undefined)
                {
                    salarie.times.forEach(element => {
                        hours += this.timeToDecimal(element.end_time) - this.timeToDecimal(element.start_time);
                        hours += this.timeToDecimal(element.second_end_time) - this.timeToDecimal(element.second_start_time);
                    });
                    return hours;
                }
                return 0;
            }
            return 0;
        },
        timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1]/6)*10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
        },
    },

    created() {
        this.loadEmployees();
    },
}
</script>

<style>

</style>